/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    img: "img",
    strong: "strong",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://foter.com/photo/industrial-storage-in-houston/"
  }, React.createElement(_components.img, {
    src: "images/industrial-storage-in-houston_l.jpg",
    alt: ""
  }))), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://www.flickr.com/photos/stuckincustoms/3930943796/"
  }, "Stuck in Customs"), " / ", React.createElement(_components.a, {
    href: "http://foter.com/travel/"
  }, "Travel Photos"), " / ", React.createElement(_components.a, {
    href: "http://www.eduteka.pl/doc/cc-by-nc-sa"
  }, "CC BY-NC-SA")), "\n", React.createElement(_components.p, null, "El otro día ", React.createElement(_components.a, {
    href: "http://litox.entramado.net/2013/06/24/curso-de-control-1-introduccion/"
  }, "Pablo hablaba en el curso sobre procesos"), ", hay tres palabras que se suelen utilizar en control como sinónimos, estas son sistema, proceso y planta. Aún así cada profesor con el que me he topado siempre ha tenido su palabra favorita, para mí estas palabras sólo son sinónimos en un contexto concreto, el ", React.createElement(_components.strong, null, "industrial"), ", paso a explicar las diferencias que encuentro:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Proceso:"), " Tal y como yo lo veo, un proceso es una transformación de materia o energía a través de una serie de pasos, querer controlarlos es un aspecto básico en la industria, es la palabra que veo que hace más referencia a una cosa única a concreta."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Planta:"), " Hace referencia a las plantas industriales, recuerda a la automatización de las fábricas que es una de las bases del control, como palabra la veo más amplia que la anterior pero siempre nos está recordando su pasado industrial."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Sistema:"), " Mi palabra preferida ya lo sabéis, sistema hace referencia a algo genérico, no tiene un pasado industrial claramente, los sistemas pueden ser muchas cosas y por eso es la palabra de la que hago uso en este curso."), "\n"), "\n", React.createElement(_components.p, null, "Este curso pretende desligar el control de su pasado electrónico e industrial, no es lo común, lo reconozco pero hay que ", React.createElement(_components.a, {
    href: "http://lasindias.net/indianopedia/Innovaci%C3%B3n"
  }, "innovar"), ". Es posible que por el camino nos adentremos en la ", React.createElement(_components.strong, null, "minería de datos"), " o en otras ciencias afines, pero no hay que perder de vista lo que es un ", React.createElement(_components.strong, null, "sistema dinámico"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
